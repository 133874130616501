import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import AppSection from '../components/organisms/AppSection';
import { initDatadogRum } from '../utils/tracking';
import { isDebug } from '../utils/utils';
import { useNavbar } from '../hooks';
import packageJson from './../../package.json';
import type { NextPage } from 'next';

initDatadogRum();
console.log(`🌏 Dashboard version ${packageJson.version}`);

if (typeof window !== 'undefined') {
  const $ = require('jquery');
  const bootstrap = require('bootstrap');
  // @ts-ignore
  window.$ = $;
  // @ts-ignore
  window.jquery = $;
  // @ts-ignore
  window.bootstrap = bootstrap;
  if (isDebug()) {
    console.log('🌏 Adding Bootstrap and JQuery from npm');
  }
}

const Home: NextPage = () => {
  const [navbarData] = useNavbar();
  const [todaysDate, setTodaysDate] = useState('');

  useEffect(() => {
    if (navbarData) {
      datadogRum.setUser({
        id: navbarData.user_id,
        name: navbarData.company?.companyName,
      });
    }
  }, [navbarData]);

  useEffect(() => {
    // only render the date on the client to prevent a mismatch between the
    // datetime on the server and client during SSR
    setTodaysDate(dayjs().format('ddd MMM D, YYYY'));
  }, []);

  return (
    <React.Fragment>
      <Script src={process.env.NEXT_PUBLIC_ASSET_UI_THEME_JS} />
      <Script src={process.env.NEXT_PUBLIC_ASSET_UI_KIT} />

      <div className="container my-1" id="jt-app">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 mb-2">  
            <div className="card border-0 w-100" id="jt-app-card">
              <div id="jt-app-header" className="card-header bg-transparent">
                <div className="row">
                  <div className="col-sm-6 d-flex align-items-center">
                    {navbarData && (
                      <h2 className="mb-sm-0 h5">
                        Welcome, {navbarData.firstName} {navbarData.lastName}
                      </h2>
                    )}
                  </div>
                  <div className="col-sm-6 d-flex justify-content-sm-end align-items-center">
                    <div className="float-right">{todaysDate}</div>
                  </div>
                </div>
              </div>
              <div className="card-body" id="jt-app-body">
                <div className="row">
                  <AppSection navbarData={navbarData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
