import React from 'react';
import { IAppForAppList } from '../../../models/interfaces';
import ListOfApps from '../ListOfApps';
import { fetchAppPermissionsAsync } from '../../../utils/apiCalls';

interface IAppSectionProps {
  navbarData: any;
}

export default function AppSection(props: IAppSectionProps) {
  const [appList, setAppList] = React.useState<IAppForAppList[] | null>(null);

  React.useEffect(() => {
    if (props.navbarData && props.navbarData.loggedIn) {
      getApps();
    }
  }, [props.navbarData?.loggedIn]);

  async function getApps() {
    try {
      const data = await fetchAppPermissionsAsync(props.navbarData.jtToken);
      // FIXME: Remove hardcoded appId filtering
      const filteredApps = data.filter((app: IAppForAppList) => app.appId !== 4 && app.appId !== 11 && app.appId !== 34);
      setAppList(filteredApps);
    } catch (err) {
      console.error('🌏 applist error', err);
    }
  }

  return (
    <div className={`col-12 ${appList ? '' : 'd-flex justify-content-center align-items-center'}`}>
      {appList && <ListOfApps appList={appList} userId={props.navbarData.user_id} navbarData={props.navbarData} />}
      {!appList && (
        <div className="spinner-border text-primary h-6 w-6" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}
