import { datadogRum } from '@datadog/browser-rum';

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATA_DOG_APPLICATION_ID || 'XXXXXX',
    clientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN || 'XXXXXX',
    site: process.env.NEXT_PUBLIC_DATA_DOG_SITE,
    service: process.env.NEXT_PUBLIC_DATA_DOG_SERVICE,
    env: process.env.NEXT_PUBLIC_DATA_DOG_ENV,
    version: process.env.NEXT_PUBLIC_DATA_DOG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};

export default datadogRum;
