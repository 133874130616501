import React from 'react';
import { IAppForAppList, INavbarData } from '../../../models/interfaces';

const APPS_WITHOUT_DASHBOARD = (process.env.NEXT_PUBLIC_APPS_WITHOUT_DASHBOARD as string).split(',');
const MODAL_TARGET = '#no-dashboard-modal';

interface IProps {
  appList: IAppForAppList[] | null;
  userId?: number;
  navbarData: INavbarData;
}

export default function ListOfApps(props: IProps) {
  function findURL(id: number | string) {
    if (id === 30 || id === 23) {
      return props.navbarData.resources.apps_by_id[id]?.url + '?entity=' + props.userId;
    }

    if (APPS_WITHOUT_DASHBOARD.includes(id.toString())) {
      return MODAL_TARGET;
    }

    return props.navbarData.resources.apps_by_id[id]?.url;
  }

  function renderApp(app: IAppForAppList, index: number) {
    if (app.status === 'Active') {
      const url = findURL(app.appId);
      return (
        <li key={index} className={`wp-xs-100 wp-sm-50 px-1 d-flex`}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={url}
            data-target={url}
            data-toggle={url === MODAL_TARGET ? 'modal' : ''}
            target={app.appId === -1 ? '_blank' : ''}
            rel={app.appId === -1 ? 'noreferrer' : ''}
            className={` flex-grow wp-xs-100 d-flex align-items-center border rounded mb-3 px-2 py-4 ${url === '#' ? 'disabled opacity-5' : ''}`}
          >
            <img className="wp-xs-10 mr-2" src={`${process.env.NEXT_PUBLIC_LOGO_URL}/${app.appId}.svg`} />
            <span className="text-15 text-dark">{app.appName}</span>
          </a>
        </li>
      );
    }
    return null;
  }

  function renderAppByResource(resourceApp: { app_name: string; app_id: number; url: string }) {
    return (
      <li className={`wp-xs-100 wp-sm-50 px-1 d-flex`}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href={resourceApp.url}
          data-target={resourceApp.url}
          data-toggle={resourceApp.url === MODAL_TARGET ? 'modal' : ''}
          target={resourceApp.app_id === -1 ? '_blank' : ''}
          rel={resourceApp.app_id === -1 ? 'noreferrer' : ''}
          className={` flex-grow wp-xs-100 d-flex align-items-center border rounded mb-3 px-2 py-4 ${resourceApp.url === '#' ? 'disabled opacity-5' : ''}`}
        >
          <img className="wp-xs-10 mr-2" src={`${process.env.NEXT_PUBLIC_LOGO_URL}/help.svg`} />
          <span className="text-15 text-dark">{resourceApp.app_name}</span>
        </a>
      </li>
    );
  }

  function renderApps() {
    if (props.appList) {
      return <React.Fragment>{props.appList.map((app, index) => renderApp(app, index))}</React.Fragment>;
    } else {
      return null;
    }
  }
  function renderAppList() {
    return (
      <ul className="list-unstyled d-flex flex-wrap justify-content-between">
        {props.navbarData && renderApps()}
        {props.navbarData && renderAppByResource(props.navbarData.resources.apps_by_id.help)}
      </ul>
    );
  }

  return <React.Fragment>{props.appList && props.navbarData && renderAppList()}</React.Fragment>;
}
