import { ICandidatesCountRequestBody, IJobCreateSettingsResponseData } from '../models/interfaces';
import { urlEnum } from '../utils/enums';

function createFetchHeaders(token: string): Headers {
  return new Headers({
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  });
}

export async function fetchPostNewJobPermissionByDivisionAsync(division: string | number, token: string): Promise<IJobCreateSettingsResponseData> {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_JOB_MANAGER_API}/JobSettings/Create?divisionId=${division}`, {
      headers: createFetchHeaders(token),
    });
    const data: IJobCreateSettingsResponseData = await response.json();
    return data;
  } catch (error) {
    console.error('🧭 Error in getPostNewJobPermissionByDivision', error);
    return {
      create: false,
      error,
    };
  }
}

export async function fetchJobActivityAsync(divisionId: string | number, token: string): Promise<any> {
  const resp: Response = await fetch(`${process.env.NEXT_PUBLIC_JOB_MANAGER_API}/Jobs/GetJobActivity?division=${divisionId}&days=5000    `, {
    headers: createFetchHeaders(token),
  });
  return await resp.json();
}

export async function fetchAppPermissionsAsync(token: string) {
  const resp = await fetch(`${process.env.NEXT_PUBLIC_DASHBOARD_API}/AppPermissions/AppPermissionsByCompany`, {
    headers: createFetchHeaders(token),
  });
  return await resp.json();
}

// GET JOB APPLICANTS
// comes from candidate manager API
// talk to Swizzer
export async function getJobApplicants({ token, ...rest }: ICandidatesCountRequestBody & { token: string }) {
  const resp = await fetch(`${urlEnum.CANDIDATE_MANAGER_API}/api/getcandidatescount`, {
    method: 'POST',
    headers: createFetchHeaders(token),
    body: JSON.stringify(rest),
  });
  return await resp.json();
}
